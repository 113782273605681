import React from "react";
import { useAuth0 } from "../react-auth0-spa";

function HomePage() {
  const { getIdTokenClaims } = useAuth0();
  console.log(getIdTokenClaims());
  return (
    <div>
      <h1>Home</h1>
    </div>
  );
}

export default HomePage;
