import React, { useState, useEffect } from "react";
import CustomerList from "../components/CustomerList";
import CustomerStore from "../stores/CustomerStore";
import { getCustomers } from "../actions/customerActions";

function CustomerPage(props) {
  const [customers, setCustomers] = useState(CustomerStore.getCustomers());

  useEffect(() => {
    CustomerStore.addChangeListener(onChange);
    if (CustomerStore.getCustomers().length === 0) getCustomers();
    return () => CustomerStore.removeChangeListener(onChange);
  }, [customers.length]);

  function onChange() {
    setCustomers(CustomerStore.getCustomers());
  }

  return (
    <div>
      <h1>Customers</h1>
      <CustomerList customers={customers.customers} />
    </div>
  );
}

export default CustomerPage;
