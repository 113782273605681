import dispatcher from "../appDispatcher";
import actionTypes from "./actionTypes";
import CustomerApi from "../api/customerApi";

export function getCustomers() {
  return CustomerApi.getCustomers().then(customers => {
    debugger;
    dispatcher.dispatch({
      actionType: actionTypes.GET_CUSTOMERS,
      customers: customers
    });
  });
}
