import React from "react";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";

function Header() {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const activeStyle = {
    color: "orange"
  };

  return (
    <nav>
      <NavLink activeStyle={activeStyle} exact to="/">
        Home
      </NavLink>
      <div>
        {!isAuthenticated && (
          <button onClick={() => loginWithRedirect({})}>Log in</button>
        )}

        {isAuthenticated && <button onClick={() => logout()}>Log out</button>}
      </div>
      <NavLink activeStyle={activeStyle} to="/customers">
        Customers (broke atm!)
      </NavLink>
      <NavLink activeStyle={activeStyle} to="/newcustomers">
        New Customers
      </NavLink>
    </nav>
  );
}

export default Header;
