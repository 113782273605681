import React, { useState } from "react";
import { useAuth0 } from "../react-auth0-spa";
import CustomerList from "../components/CustomerList";

function NewCustomersPage() {
  const [showResult, setShowResult] = useState(false);
  const [customers, setCustomers] = useState([]);
  const { getTokenSilently } = useAuth0();

  const callApi = async () => {
    try {
      const token = await getTokenSilently();

      const response = await fetch("/customers", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const responseData = await response.json();

      setShowResult(true);
      setCustomers(responseData);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h1>New Customers</h1>
      <button onClick={callApi}>Get Customers</button>
      {showResult && <CustomerList customers={customers.customers} />}
    </div>
  );
}

export default NewCustomersPage;
