import React from "react";
import PropTypes from "prop-types";

function CustomerList(props) {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>First Name</th>
          <th>Last Name</th>
        </tr>
      </thead>
      <tbody>
        {props.customers && props.customers.length > 0 ? (
          props.customers.map(customer => {
            return (
              <tr key={customer.id}>
                <td>{customer.id}</td>
                <td>{customer.first_name}</td>
                <td>{customer.last_name}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td>Loading...</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

CustomerList.propTypes = {
  customers: PropTypes.array.isRequired
};

export default CustomerList;
