import React from "react";
import Header from "./common/Header";
import { Route, Switch } from "react-router-dom";
import HomePage from "../pages/HomePage";
import CustomerPage from "../pages/CustomerPage";
import NewCustomersPage from "../pages/NewCustomersPage";
import LoginPage from "../pages/Login";
import { useAuth0 } from "../react-auth0-spa";

function App(props) {
  const { loading } = useAuth0();

  if (loading) {
    return <div>Loading</div>;
  }

  return (
    <>
      {/* <AuthContext.Provider value={appState.auth}> */}
      <Header />
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/customers" component={CustomerPage} />
        <Route path="/newcustomers" component={NewCustomersPage} />
      </Switch>
      {/* </AuthContext.Provider> */}
    </>
  );
}

export default App;
