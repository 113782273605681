import { handleResponse, handleError } from "./apiUtils";
import { useAuth0 } from "../react-auth0-spa";

const CustomerApi = () => {
  const { getTokenSilently } = useAuth0();

  const getCustomers = async () => {
    try {
      const token = await getTokenSilently();

      const response = await fetch("/customers", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const responseData = await response.json();
      debugger;
      return responseData;
    } catch (error) {
      debugger;
      console.error(error);
    }

    // return fetch("/customers")
    //   .then(handleResponse)
    //   .catch(handleError);
  };

  return {
    getCustomers: getCustomers
  };
};

export default {
  getCustomers: CustomerApi
};
